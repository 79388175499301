
$max-width: 1200px;
$mobile-width: 600px;

/* ---- base ---- */

/*   .preloader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background:#c6d9de;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preloader.hidden {
  display: none;
}

.lds-spinner rect {
  fill: #01577b;
}

.preloader svg {
 width: 200px;
 height: 200px;
 transform-origin: 50px 50px;

 }

 canvas{
display:block;
vertical-align:bottom;
opaci ty: 0.8;
}
*/

body {
  background: #181818;
}



.main-nav-inner li a {
  color: #dedfe0;;
  text-decoration: none;
  font-size: 13px;
}

p {
  font-family: "Raleway", sans-serif;;
}

.logo {
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
}

/* .navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 58px;
  background: #01577b;
  border-bottom: ;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate3d(0,-60px, 0) ;
  transition: all 0.7s ease-out;
  transition-delay: 0.3s;
  a {
    color: white;
    text-decoration: none;
  }
}

.navbar .navholder {
   width: 1200px;
   display: flex;
   justify-content: space-between;
}

.navbar .menu {
  width: 50%;
  padding-top: 4px;
  display: flex;
  justify-content: space-between;
  } */


  .right i {
    font-size: 25px;
  }


  .section-title {
    margin-bottom: 60px;
    &.usecase_title {
      margin-bottom: 30px;
    }
    @media screen and (max-width: $mobile-width) {
margin-bottom: 10px;
  }
  }

  .main-nav {
    /* display: flex;
    align-items: center; */
  }
  .phone_number {
    color: #dee3e4 ;
    font-family: sans-serif;
    font-size: 14px;
  }

  .flex {
    display: flex;
    justify-content: center;
  }
  P.introd {
    width: 60%;
    text-align: center;
    padding-bottom: 20px;
     @media screen and (max-width: $mobile-width) {
width: 100%;

  }
  }


  .table {
    th {
      font-size: 18px;
      width: 50% !important;
      max-width: 50% !important;
    }
    td {
      width: 50% !important;
      max-width: 50% !important;
    }
  }
  .table .thead-dark th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e;
  }
/* test */
  .table-pricing {
    th {
      font-size: 18px;
      width: 33% !important;
      max-width: 50% !important;
      text-align: center;
      padding-bottom: 5px;
      padding-top: 5px;

    }
    td {
      width: 33% !important;
      max-width: 50% !important;
      text-align: center;
    }
  }
  .table-pricing .thead-dark-pricing th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e;
  }

  .table-consulting {
    td {
    font-size: 15px !important;
    padding-top: 5px;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
    }
  }

/* test end */
  .table-striped tbody tr:nth-of-type(2n+1) {
    background-color: rgba(0,0,0,.05);
  }

  .font-alt {
    font-family: inherit;
    font-style: normal;
    font-size: 20px;
    /* color: gray; */
  }

  .section-padding {
    padding-top: 60px;
    padding-bottom: 60px;
     @media screen and (max-width: $mobile-width) {
padding-bottom: 30px;
padding-top:30px;
  }
  }

  .section-lesspadding {
    padding-top: 20px;
    padding-bottom: 50px;
    @media screen and (max-width: $mobile-width) {
padding-bottom: 20px;
  }
  }

  .header_div {
    position: absolute;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logo_holder {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: translate3d(0,60px, 0) scale(0.8);
    transition: all 0.7s ease-out;
    transition-delay: 0.2s;
  }
  .logo_holder img {
    width: 600px;
    max-width: 86vw;
    
  }
  .cls-1{fill:white ;}
  .cls-2{fill:white !important;}
  .cls-3{fill:white !important;}
  .cls-4{fill:white !important;}



  @media screen and (max-width: $mobile-width) {
   .header {
    background: none;
    border: 0px;
  }
  .logo a {
    color: #eee;
  }
  .mobile-menu a i, .mobile-menu a.active i {
    color: #eee;
  }
  .mobile-menu a {
    color: #eee;
  }
  .main-nav-inner {
  padding-top: 15px !important;
  }
  .main-nav {
    background-color: rgb(24, 24, 25);
  }
  .stuck .mobile-menu a, .stuck  .mobile-menu a i {
    color: #323232;
  }
}





.sublogo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h3 {
    font-size: 32px;
    color: #dadada;
    opacity: 0;
    text-align: center;
    text-transform: none;
    margin-top: 30px;
    margin-bottom: 32px;
    font-weight: normal;
    transition: all 0.8s ease-out;
    transition-delay: 0.35s;
    transform: translate3d(0,90px, 0) scale(0.8);
    @media screen and (max-width: $mobile-width) {

     font-size: 20px;
   }
 }
}
.buttons {
  display: flex;
  justify-content: center;
}  
.header_div button {
  height: 48px;
  width: 180px;
  border-radius: 20px;
  background: #01577b;
  border: 0px;
  font-size: 15px;
  margin-left: 6px;
  margin-right: 6px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  padding-top: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  @media screen and (max-width: $mobile-width) {
    width: 120px;
    float: none;
    font-size: 13px;
  }
  &.bu1 {
    transition: all 0.8s ease-out;
    opacity: 0;
    transition-delay: 0.5s;
    transform: translate3d(0,100px, 0) scale(1);
  }
  &.bu2 {
    opacity: 0;
    transition: all 0.85s ease-out;
    transition-delay: 0.6s;
    transform: translate3d(0,100px, 0) scale(1);
    background: white;
    color: black;
  }
}

.bu2 {
  background-color: #fff;
  /* border: #01577b 1px solid; */
  color: #01577b;
}


.onscreen {
 opacity: 1 !important;
 transform: translate3d(0,0,0) !important;
}


/* .cls-1{fill:#01577b;}.cls-2{fill:#5ec4e1;}.cls-3{fill:#1a1a18;}.cls-4{fill:url(#linear-gradient);}.cls-5{fill:#fff;} */
/* ---- particles.js container ---- */

#particles-js{
  width: 100%;
  height: 100vh;
  transform: scale(1.4);
  transition: all 1.5s ease-in-out;
  background-image: url();
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 1;
}

#particles-js.loaded {
 transform: scale(1);
}

.icon-box.right {
  padding-right: 20px;
}
.icon-box.left {
  padding-left: 20px;
}

.icon-box {
  margin-top: 25px;
  margin-bottom: 40px;
  @media screen and (max-width: $mobile-width) {
margin-bottom: 10px;
text-align: left !important;
padding-left: 20px;
padding-right: 0px;
  }
}

.pre_icon {
  display: flex;
  justify-content: center;
  width: 100%;
  .texter {
    display: flex;
    justify-content: center;
    p {
      width: 70%;
      text-align: center;
    }
  }
}

#sciwork {
  .offering {
   /*  display: flex;
    justify-content: space-between;
    flex-wrap: wrap;  */
  }

  .icon-sphere {
    margin-right: -4px;
  }
  .holder {
    margin: 10px;
  max-width: 400px;
    border: #e0dede 1px solid;
    border-radius: 5px;
    
  }
  .texter {
    padding: 10px;
    text-align: center;
  }

  .ico {
    padding: 10px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    
    text-align: center;
  }
  .keeper {
    width: 120px;
    height: 120px;
    background: #eee;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.big_icon {
  font-size: 70px;
}

.second {
  min-height: 100vh;
  background: #fff;
  display: flex;

  align-items: center;
  flex-direction: column;
  h2 {
    font-size: 50px;
  }
  .holder {
    width: 100%;
    max-width: $max-width;
    display: flex;
    justify-content: center;
    .phone {
      display: flex;
      justify-content: center;
    }
  }
}

#machinevs {
   @media screen and (max-width: $mobile-width) {
    padding-top: 30px;
  }
}

#investors {
  background: black;
}

.slider-item, .testimonial-slider {
  height: 100%;
}

.darker {
  background: #f3f3f3;
}

h2 {
  color: #63909e;
}

.usecase {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: -30px;
  height: 100%;
  align-items: center;
  .top_icons {
    display: flex;
    justify-content: space-evenly;
    margin-left: 20px;
    padding-top: 0px;
    width: 78%;
     @media screen and (max-width: $mobile-width) {
    flex-wrap: wrap;
    margin-left: 0;
    justify-content: flex-start;
    align-items: center;
  }
    
  }
  .ico_holder {
    display: flex;
    
    margin: 7px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
  }
  .ico_div {
    opacity: 0.8;
    font-size: 42px;
    display: flex;
    justify-content: center;
    color: #63909e;
  }

  .meta {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10px;
  }
  .meta span {
    font-size: 12px;
    color: gray;
    margin-top: 18px;
    line-height: 0px;
  }
  .meta h4 {
    text-transform: none;
    font-size: 13px;
    text-align:center;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 0px;
    span {
      color: gray;
      font-weight: normal;
    }
  } 
  .meta h5 {
    font-size: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: gray;
  }
  .case_position .ico_div {
    font-size: 38px;
  }

  .icon-stats-bars2 {
    margin-bottom:3px;
  }
}

.slider-item .content_holder {
  background: #f3f3f3;
  position: relative;
  z-index: 10;
}

.usecase_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  /* background: #f3f3f3; */
  .item {
    margin-top: 15px;
    display: flex;
    justify-content: left;
    border: #63909e  2px solid;
    padding: 0px;
    background: #f3f3f3;
    border-radius: 9px;
    position: relative;
    z-index: 3;
    @media screen and (max-width: $mobile-width) {
    flex-direction: column;
    margin-right: 10px;
    margin-left: 10px;
  }
    h4 {
      font-size: 13px;
      text-transform: none;
      margin-bottom: 8px;
      margin-top: 8px;
      text-align: center;
      line-height: 18px;
      color: white;
      font-weight: normal;
       @media screen and (max-width: $mobile-width) {
     text-align: left;
     margin-left: 10px;
     font-size: 15px;
  }
    }
    .ico {
      font-size: 20px;
      /*  box-shadow: inset 11px 0px 23px -23px rgba(0,0,0,0.45); */
       color: #f3f3f3;
      background: #63909e;
      /* width: 130px; */
      width: flex;
      display: flex;
     flex-direction: column;
      justify-content: center;
      align-items: center;
       @media screen and (max-width: $mobile-width) {
         flex-direction: row;
   width: 100%;
   padding-bottom: 12px; 
   padding-top: 12px;
    font-size: 30px;
  }
      span {
        font-size: 40px;
      }
    }
    .texter {
      width: 100%;
      width: 600px;
      padding: 10px;
      padding-right:10px;
      @media screen and (max-width: $mobile-width) {
   width: auto;
  }
      p {
       font-size: 13px;
       text-align: justify;
     }

   }
 }
}

.usecase .content_holder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height:556px;
    @media screen and (max-width: $mobile-width) {
    height:auto;
    padding-bottom: 30px;
  }
  .compass span {
    font-size: 16px;
    margin-top: 10px;
    @media screen and (max-width: $mobile-width) {
    font-size: 12px;
     margin-top: 0px;
  }
  }
  .bell_ico span {
    font-size: 55px;
    margin-top: 10px;
     @media screen and (max-width: $mobile-width) {
    font-size: 27px;
     margin-top: 0px;
  }
  }
  .award span {
    margin-top: 10px;
    margin-right: -5px;
    @media screen and (max-width: $mobile-width) {
    margin-right:0px;
     margin-top: 0px;
  }
  }
  
}

.owl-theme .owl-nav {
  position: absolute;
  top: 50%;
  width: 90%;
  left: 5%;
  margin-top: -30px;
  opacity: 0.6;
  display: flex;
  justify-content: space-between;

}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
  font-size: 40px;
  color: #63909e;
   @media screen and (max-width: $mobile-width) {
   display: none;
  }
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: none;
  color: black;
  text-decoration: none;
}

.ico_holder:first-child {

}

.owl-stage-outer {
  margin-bottom: 20px;
}

.testimonial-slider .ico_holder {
  opacity: 0;
  transform: translate3d(0, 100px, 0);
}
.testimonial-slider .owl-item.active .ico_holder {
  transition: all 0.8s cubic-bezier(0, 0, .58, 1);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.testimonial-slider .owl-item.active .ico_holder:nth-child(2) {
  transition-delay: 0.14s;
}

.testimonial-slider .owl-item.active .ico_holder:nth-child(3) {
  transition-delay: 0.3s;

}

.testimonial-slider .owl-item.active .ico_holder:nth-child(4) {
  animation-duration: 1.55s;
}

.testimonial-slider .owl-item.active .slider-item {
  transition: all 0.3s cubic-bezier(0, 0, .58, 1);
  animation-name: none;

}

/* 
.testimonial-slider .owl-item.active .content_holder{
  transition: all 0.3s cubic-bezier(0, 0, .58, 1);
  animation-name: slideInDown;
  animation-duration: 1.3s;
  animation-delay: 0.1s;
  } */


  .testimonial-slider .owl-item.active .item{
    transition: all 0.3s cubic-bezier(0, 0, .58, 1);
    animation-name: fadeeInDown;
    animation-duration: 1.3s;

  }

  .testimonial-slider .owl-item.active .item:nth-child(2) {
    animation-delay: 0.25s;
  }

  .testimonial-slider .owl-item.active .item:nth-child(5) {
   animation-delay: 0.55s;
 }

 .mockup-back.wow {
  animation-duration: 1s;
} 

/* #trapezoid {    border-bottom: 100px solid red;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  height: 0;
  width: 100px;
}

#impact {
  svg {
   height: 580px;

 }
}

.pyramid_holder {
 display: flex;
 justify-content: center;
 height: 620px;
 margin-top: 30px;
 .description {
   margin-top: -20px;
   margin-left: 50px;
   width: 500px;
   display: flex;
   height: 100%;
   flex-direction: column;
   justify-content: space-evenly;
   h3 {
     text-align: left;
     margin-bottom: 5px;
   }
   ul {
    
   }
 }
 
}

.pyramid_holder .description div:nth-child(1) {

  transform: translate3d(-150px,0,0);
  h3 {
    color:orange;
  }

}

.pyramid_holder .description div:nth-child(2) {

  transform: translate3d(-75px,0,0);
  h3 {
    color:#e07558;
  }

}

.pyramid_holder .description div:nth-child(3) {


  h3 {
    color:#56bbc6;
  }

  } */


  #team {
    padding-top: 0px;
    padding-bottom: 80px;
  }
  .team-member-info {
    h5 {
      color: #197e9c;
    }
  }
 /*  .dl-btn.btn-1 {
    border-radius: 100px;
  } */
.dl-btn {
min-width:120px;
}
  .download  {
    color: white;
     @media screen and (max-width: $mobile-width) {
padding-bottom: 10px;
  }
    h4 {
     margin-top: 0px;
     color: white;
   }
   h2.mb-35 {
    margin-bottom: 10px;
  }
  .teaser {
    margin-bottom: 60px;
  }
}

.icon-box a {

  color: #e6e6e6;
}

.bg-light {
  background-color: #202129;
  h2 {
    color:white;
  }
}

.btn-ellipse {
  background: #ddd;
}

.contact-box.left {
     @media screen and (max-width: $mobile-width) {
margin-bottom: 10px;
margin-top: 10px;
padding-left: 20px;
  }
}

.data_privacy {
  h3 {
    margin-top: 40px;
  }
  h2 {
    color: black;
    margin-top: 40px;
  }
  h4 {
    margin-top: 30px;
    margin-bottom: 5px;
  }
}

.footer_contact {
  color: white;
  font-family: tahoma;
  font-size: 14px;
  a {
    color: white;
    text-decoration: underline;
  }
}

.footer_nav {
  list-style: none;
    @media screen and (max-width: $mobile-width) {
        margin-left: 0px;
        padding-left: 0px;
        margin-bottom: 40px;
        margin-top: 30px;
  }
}

.privacy_but {
  background: black;
  color: white;
}

.privacy_but:hover {
  color: white;
}

.modal-header {
  h5 {
    float: left;
  }
  
}

.modal-body {
  clear: both;
}

.modal-body {
  color: black !important;
  p {
    color: black;
  }
  a {
    color: #047c86;
    font-weight: bold;
  }
}

.copyright {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .icons {
     @media screen and (max-width: $mobile-width) {
        text-align: center;
        display: flex;
        justify-content: center;
        width: 100%;
  }
  }
  .icons a {
    font-size: 22px;
    color: gray;
    margin-left: 10px;
    margin-right: 10px;
  }
  .texter {
    text-align: left;
    font-family: sans-serif;
     @media screen and (max-width: $mobile-width) {
        margin-bottom: 10px;

  }
  }
}

#subscribe_message {
  h4 {
    color: cyan;
    margin: 20px;
  }
}


#demo_usecase {

  .modal-content {
    background: #2c2b2b;
  }
  .modal-header {
    background: #acbcc3;
    border-bottom: #185e72 0px solid;
    border-radius: 5px 5px 0px 0px;
    padding: 20px;
    padding-bottom: 20px;
  }
}

.bigicon {
  font-size: 25px;
  color: #5ec4e1;
}

.demo_usecase {
  display: flex;
  justify-content: center;
  color: white;
  label  {
    color: #a4a4a4;
    font-weight: normal;
    cursor: pointer;
  }
  .intro_text {
    color: white;
  }
  input.form-control {
    border: 0px solid;
    background: transparent;
    color: white;
    font-size: 17px;
  }
  .d_form_holder {
    width: 100%;
    max-width: 500px;
  }
  .form-group {
    border: #757474   1px solid;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    
  }
  form {
    width: 100%;
  }
  fieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .submit_row button {
    width: 240px;
    background: #5ec4e1;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}